
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.selected-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.25rem;
  user-select: none;
  transition: border-color 0.3s;
}

.selected-option img {
  width: 1.9375rem;
  height: 1.1875rem;
  margin-right: 0.625rem;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;

  background-color: #fff;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
  z-index: 1;
}

.option {
  display: flex;
  align-items: center;
  padding: 0.0625rem 0.625rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: #f5f5f5;
}

p{
  margin: 0rem;
}

.option img {
  width: 1.5rem;
  height: 0.9375rem;
  margin-right: 0.625rem;
}
.down-arrow{
  display: flex;
}
.down-arrow::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  border-top: 0.3125rem solid #333; 
  margin-left: 0.3125rem; 
  transition: transform 0.3s;
}

.selected-option.open .down-arrow::before {
  transform: rotate(180deg);
}

@media (max-width: 46.25rem) {

  .selected-option {
    padding: 0.625rem;
  }


}

