.page-not-found-image
{
    width: 28.75rem;
    height: 12.75rem;
}
.pageContainer
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.headerText
{
    letter-spacing: 0rem;
    color: #000000;
    opacity: 1;
}
.backbutton
{
    width: 10rem;
    height: 3rem;
    background: #E20514 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    opacity: 1;
    margin: 0.625rem;
    border: none;
}


@media (max-width: 37.5rem) {
    .pageContainer {
        text-align: center;
      }
    
      .page-not-found-image {
        max-width: 80%; 
        height: auto; 
      }
    
      .headerText {
        font-size: 1.5rem;
      }
    
      .backbutton {
        font-size: 1rem;
      }
}