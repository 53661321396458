.selectBox {
    width: 33.5rem;
    margin-top: 3rem;
    position: relative;
}

.custom-select input {
    margin-left: 3.375rem !important;
}

.placeholder {
    margin-left: 3.375rem;
}

.search-icon {
    width: 1.5625rem;
    height: 1.125rem;
}

.search-icon-absolute {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    z-index: 99;
}

#react-select-3-listbox {
    margin-block: 0rem;
}

@media(max-width:34.375rem) {
    .selectBox {
        width: 100%;
        margin-top: 0.625rem;
    }

    .placeholder {
        font-size: 0.875rem;
        margin-left: 1.5625rem;
    }

    .search-icon-absolute {
        width: 1rem;
        height: 1rem;
        left:0.5rem;
        top:1.375rem;
    }

    .custom-select input {
        margin-left: 1.5625rem !important;
    }
}