.backgoundContainer {
    background-color: var(--custom-background);
    height: 100%;
    padding-bottom: 1.875rem;
}

.logoContainer {
    background-color: var(--white);
    text-align: center;
    margin-inline: auto;
    max-width: 68rem;
    /* min-width: 21.875rem; */
    /* width: var(--custom-width); */
}

.logo {
    width: 16.625rem;
    height: 9.75rem;
    margin-block: 2.25rem;

}


.product-details-container {
    max-width: var(--custom-width);
    margin-inline: auto;
    background: var(--white);
    margin-block: 1.5rem;
    padding: 0.625rem 0rem;
    align-items: center;

    display: flex;
    justify-content: space-between;

}

.header-margin{
    margin-block: 0rem;
}

.margin-wrapper {
    margin-inline: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.banner-container {
    max-width: var(--custom-width);
    margin-inline: auto;
    height: 3.75rem;
    text-align: center;
    font-size: 1.125rem;
}

.content-container {
    max-width: var(--custom-width);
    background-color: var(--white);
    margin-inline: auto;
}

.content-container div {
    margin-block: 1.25rem;
}


.grid-layout {
    display: grid;
    grid-template-columns: 13.4375rem 2fr;
    margin-inline: 5.75rem;
}

.solid {
    border-top: 0.0625rem solid #d5d3d3;
    margin-inline: 5.75rem;
}

.content-margin {
    margin-left: 1.25rem;
}


.tableHeading{
    margin-top: 0rem;
}

.productInfo{
  display: flex;
  gap: 1.25rem;
  margin-top: 0.25rem;
}

.ProductArticle
{
    display: flex;
    justify-content: space-between;
    gap: 1.25rem;
    margin-top: 0rem;
}

.detailsContainer{
  margin-left: 6.25rem;
}

.desktop-back-to-search{
    margin-right: 3.375rem;
    position: relative;
    cursor: pointer;
}
.desktop-back-to-search span{
    margin-left: 2.1875rem;
}

.pdt-search-icon{
  position: absolute;
}

@media (max-width: 37.5rem) {
    .logoContainer {
        margin-inline: auto;
        max-width: 21.875rem;
        height: 12.5rem;
    }

    .logo {
        width: 13.8125rem;
        height: 8.125rem;
        margin-block: 1.3125rem;
    }

    .banner-container {
        max-width: 21.875rem;
        font-size: 0.875rem;
    }

    .content-container {
        max-width: 21.875rem;
    }

    .grid-layout {
        display: flex;
        flex-direction: column;
        margin-inline: 0rem;
        /* align-items: baseline; */
    }
    
    .grid-layout > h4 {
        margin-left: 0.938rem;

    }
    
    .solid{
        margin-inline: 1.0625rem;
    }

    .content-margin{
        margin-inline: 0.9375rem;
    }p
    .Product-header-text
    {
        font-size: 1.125rem;
        text-align: left;
        margin-left: 0.3125rem;
        margin-inline: 0.9375rem;
    }

    .product-details-container{
        flex-direction: column;
        max-width: 21.875rem;
    }
    .detailsContainer{
        margin-left: 0rem;
    }
    .desktop-back-to-search{
        margin-top: 0.625rem;
    }
    .dropdownContainer
    {
        padding:0rem;
    }
}
