.searchPageWrapper {
    margin-top: 6.25rem;
    max-width: 57.5rem;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 1.25rem;
    padding-bottom: 12.5rem;
}


.search-logo {
    width: 16.25rem;
    height: 9.5rem;
}

.vl {
    border-left: 0.1875rem solid rgb(235 235 235);
    height: 18.75rem;
    margin-bottom: 1.0625rem;
}

.InfoWrapper {
    display: flex;
    gap:3.75rem;
    align-items: center;
}

.search-banner {
    height: 2.5rem;

}

.pageInfo p{
   margin-top:1.5rem;
   line-height: 1.5rem;
   text-align: justify;
}
.dropdownContainer
{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 46.25rem) {

    .searchPageWrapper {
        margin-top: 1.875rem;
        padding-bottom: 12.5rem;
     }

    .InfoWrapper{
        flex-direction: column ;
        gap:0.8rem;
    }
    .vl {
        border-top: 0.1875rem solid rgb(235 235 235);
        width: 21.875rem;
        height: 0rem;
        border-left: 0rem;
        margin:0rem;
    }
    
    .search-logo {
        width: 10.6875rem;
        height: 6.25rem;
    }

}
 