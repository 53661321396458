:root {
  --custom-width: 1088px;
  --custom-background: #F2F2F2;
  --white: #FFFFFF;
  --banner-red: #E20514;
}

 @font-face {
  font-family: "Nue";
  src:url("./fonts/NeuePlakUA.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
} 

@font-face {
  font-family: "Nue";
  src:url("./fonts/NeuePlakUABd.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
} 
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Nue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: 'Helvetica Neue', sans-serif;
}

table,
th,
td {
  border: 1px solid #F0F0F0;
  border-collapse: collapse;
  padding-inline: 30px;
  padding-block: 10px;
  text-align: center;

}



th{
   background: #D1D1D1;
}


th:first-of-type {
  border-top-left-radius: 10px;
}
th:last-of-type {
  border-top-right-radius: 10px;
}
tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
}
tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}



.red-banner {
  background: var(--banner-red);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}



@media (max-width: 600px) {
 
table,
th,
td {
  border: 1px solid #F0F0F0;
  border-collapse: collapse;
  padding-inline: 1px;
  padding-block: 10px;
  text-align: center;
}

th{
  font-size: 12px;
}

td{
  font-size: 12px;
  padding-inline:26px;
}
}
